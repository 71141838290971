<template>
  <v-app id="inspire">
    <div class="utils-menu">
      <v-lazy transition="scale-transition">
        <Tutos
          v-if="
            $store.getters.isAuthenticated &&
            $router.currentRoute.path !== '/help'
          "
        />
      </v-lazy>
      <v-menu
        offset-y
        top
        rounded="lg"
        transition="scroll-y-reverse-transition"
      >
        <template #activator="{ on, attrs }">
          <v-btn small fab v-bind="attrs" depressed v-on="on">
            <CountryFlag
              :country="$i18n.locale"
              rounded
              class="mt-n2 mr-n2"
              style="transform: scale(0.4)"
            />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(lang, index) in [
              { name: 'Français', flag: 'fr', value: 'fr' },
              { name: 'English', flag: 'gb', value: 'gb' },
            ]"
            :key="index"
            @click="changeLocale(lang.value)"
          >
            <v-list-item-icon>
              <CountryFlag :country="lang.flag" rounded class="mt-n2" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ lang.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-navigation-drawer v-model="drawerL" app disable-resize-watcher temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Rapidbook.fr</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in itemsWithCondition"
          :key="item.title"
          :to="{ name: item.route }"
          exact
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$store.getters.isAuthenticated && $store.getters.isChild"
          style="position: absolute; bottom: 20px"
        >
          <div class="caption">
            {{ $t('global.parent') }} : {{ $store.getters.getParentUsername }}
          </div>
        </v-list-item>
        <v-list-item
          v-if="$store.getters.isAuthenticated"
          style="position: absolute; bottom: 0"
        >
          <div class="caption">
            {{ $t('global.user') }} : {{ $store.getters.getUsername }}
          </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="drawerR"
      app
      disable-resize-watcher
      fixed
      right
      temporary
      width="500"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $t('menu.cart') }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn fab text @click="drawerR = !drawerR">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <panier></panier>
    </v-navigation-drawer>

    <v-app-bar app fixed color="white" elevation="0">
      <v-app-bar-nav-icon @click="drawerL = !drawerL"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <div class="d-flex mt-1 ml-n15 align-center">
          <v-img
            class="ml-5 ml-sm-0"
            contain
            height="64"
            lazy-src="@/assets/img/Rapidbook_web.svg"
            src="@/assets/img/Rapidbook_web.svg"
            width="300"
          />
          <span v-if="$vuetify.breakpoint.smAndUp" class="ml-n10 mr-2">By</span>
          <v-img
            v-if="$vuetify.breakpoint.smAndUp"
            contain
            height="50"
            lazy-src="@/assets/img/logo-corlet-2021.png"
            src="@/assets/img/logo-corlet-2021.png"
            width="50"
          />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$store.getters.isAuthenticated && $store.getters.isAdmin"
        :to="{ name: 'Admin' }"
        fab
        text
      >
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
      <v-chip
        v-if="count > 0 && $store.getters.isAuthenticated"
        class="px-2"
        color="yellow"
        pill
        small
      >
        {{ count }}
      </v-chip>
      <div>
        <v-btn
          v-if="$store.getters.isAuthenticated && !$store.getters.isChild"
          fab
          text
          @click="getPanier()"
          :class="count > 0 ? 'swing' : ''"
        >
          <v-icon>mdi-cart-outline</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer dark>
      <v-row justify="center" no-gutters>
        <v-btn
          :to="'contact'"
          class="my-2 border-shaped mr-2"
          color="white"
          text
        >
          {{ $t('menu.contact') }}
        </v-btn>
        <v-btn :to="'mentions'" class="my-2 border-shaped" color="white" text>
          {{ $t('menu.mentions') }}
        </v-btn>
        <v-col class="py-4 text-center" cols="12">
          Copyright © {{ new Date().getFullYear() }} All Rights Reserved by
          Rapidbook by Corlet
        </v-col>
      </v-row>
    </v-footer>
    <v-snackbar v-model="snackbar" :color="color" bottom left timeout="5000">
      {{ text }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Fermer</v-btn>
      </template>
    </v-snackbar>
    <div class="page-loader d-flex align-center justify-center elevation-1">
      <v-img
        class="image"
        contain
        height="300"
        lazy-src="@/assets/img/Rapidbook_web.svg"
        src="@/assets/img/Rapidbook_web.svg"
        width="300"
      />
    </div>

    <v-dialog v-model="dialog" shaped width="500">
      <v-card v-if="info" elevation="24" shaped>
        <v-card-title class="text-h5 mb-4">
          {{ $t('global.info') }}</v-card-title
        >

        <v-card-text class="text-justify">
          <pre class="text-body-1 text-pre-wrap text-break">{{ info }}</pre>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="border-shaped yellow--bg"
            depressed
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <cookie-consent
      link-label=""
      :message="$t('global.cookie-message')"
      style="background-color: white"
    >
      <template slot="button">
        <v-btn class="border-shaped yellow--bg" depressed> Accept</v-btn>
      </template>
    </cookie-consent>
  </v-app>
</template>

<script>
import Panier from '@/components/info/Panier'
import Tutos from '@/components/info/Tutos'
import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  components: { Panier, Tutos },
  data: () => ({
    dialog: false,
    info: '',
    drawerL: false,
    drawerR: false,
    count: 0,
    snackbar: false,
    color: 'gray',
    text: '',
  }),
  computed: {
    itemsWithCondition: function () {
      const items = [
        {
          title: this.$t('menu.home'),
          icon: 'mdi-home-outline',
          route: 'Home',
          condition: true,
        },
        {
          title: this.$t('menu.dashboard'),
          icon: 'mdi-view-dashboard-outline',
          route: 'Dashboard',
          condition: this.$store.getters.isAuthenticated,
        },
        {
          title: this.$t('menu.library'),
          icon: 'mdi-bookshelf',
          route: 'Library',
          condition: this.$store.getters.isAuthenticated,
        },
        {
          title: this.$t('menu.orders'),
          icon: 'mdi-format-list-checkbox',
          route: 'Orders',
          condition:
            this.$store.getters.isAuthenticated && !this.$store.getters.isChild,
        },
        {
          title: this.$t('menu.address'),
          icon: 'mdi-map-marker-outline',
          route: 'Address',
          condition:
            this.$store.getters.isAuthenticated && !this.$store.getters.isChild,
        },
        {
          title: this.$t('menu.account'),
          icon: 'mdi-account-outline',
          route: 'Account',
          condition: this.$store.getters.isAuthenticated,
        },
        {
          title: this.$t('menu.simulation'),
          icon: 'mdi-poll',
          route: 'Simulation',
          condition:
            this.$store.getters.isAuthenticated && !this.$store.getters.isChild,
        },
        // {
        //   title: 'Nouveaux Tarifs',
        //   icon: 'mdi-poll',
        //   route: 'SimulationTest',
        //   condition:
        //     this.$store.getters.isAuthenticated && this.$store.getters.isAdmin,
        // },
        {
          title: this.$t('menu.login'),
          icon: 'mdi-login-variant',
          route: 'Login',
          condition: !this.$store.getters.isAuthenticated,
        },
        {
          title: this.$t('menu.contact'),
          icon: 'mdi-message-outline',
          route: 'Contact',
          condition: true,
        },
        {
          title: this.$t('menu.admin'),
          icon: 'mdi-cog-outline',
          route: 'Admin',
          condition:
            this.$store.getters.isAuthenticated && this.$store.getters.isAdmin,
        },
        {
          title: this.$t('menu.register'),
          icon: 'mdi-account-plus-outline',
          route: 'Register',
          condition:
            this.$store.getters.isAuthenticated && this.$store.getters.isAdmin,
        },
        {
          title: this.$t('menu.logout'),
          icon: 'mdi-logout-variant',
          route: 'Logout',
          condition: this.$store.getters.isAuthenticated,
        },
        {
          title: this.$t('menu.need_help'),
          icon: 'mdi-comment-question-outline',
          route: 'Help',
          condition: this.$store.getters.isAuthenticated,
        },
      ]
      return items.filter((i) => i.condition)
    },
  },
  mounted() {
    this.$i18n.locale = this.$store.getters.getLocale
    this.$vuetify.lang.current = this.$i18n.locale
    this.$store.dispatch('changeLocale', this.$i18n.locale)
    this.getPanierCount()

    EventBus.$on('refreshPanier', () => {
      this.getPanierCount()
    })

    EventBus.$on('notif', (text, color) => {
      this.text = text
      this.color = color
      this.snackbar = true
    })
    setTimeout(() => {
      document
        .querySelector('.page-loader')
        .setAttribute('style', 'transform: translateY(-100vh)')
      document.querySelector('.page-loader').classList.remove('elevation-1')
    }, 2500)
    this.$nextTick(() => {
      this.getInfos()
    })
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang
      this.$vuetify.lang.current = this.$i18n.locale
      this.$store.dispatch('changeLocale', this.$i18n.locale)
    },
    getPanier() {
      if (!this.$store.getters.isChild) {
        this.drawerR = !this.drawerR
        if (this.drawerR) {
          EventBus.$emit('refreshPanier')
        }
      }
    },
    getPanierCount() {
      if (this.$store.getters.isAuthenticated && !this.$store.getters.isChild) {
        axios
          .post(
            process.env.VUE_APP_API_URL + '/panier',
            {},
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              },
            }
          )
          .then((response) => {
            this.count = response.data.count
          })
          .catch(() => {})
      }
    },
    getInfos() {
      axios
        .get(process.env.VUE_APP_API_URL + '/information', {
          headers: this.$store.getters.isAuthenticated
            ? {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              }
            : null,
        })
        .then((response) => {
          this.info = response.data.information
          if (this.info.length > 0) {
            this.dialog = true
          }
        })
        .catch(() => {
          this.info = ''
        })
    },
  },
}
</script>

<style lang="scss">
@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent';
@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom';
@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition';
@import 'https://emoji-css.afeld.me/emoji.css';
:root {
  --color-yellow: #ffd600 !important;
  --overlay-bg: #ffd600 !important;
}

.overlay-top,
.overlay-bottom,
.overlay-left,
.overlay-right {
  z-index: 9999;
}

.yellow--text-override {
  color: var(--color-yellow) !important;
}

.yellow--bg {
  background-color: var(--color-yellow) !important;
  color: white;
}

.border-shaped {
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.border-shaped-tel .v-text-field--outlined fieldset {
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.yellow--override {
  background-color: var(--color-yellow) !important;
  border-color: var(--color-yellow) !important;
}

.page-loader {
  background: white;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  animation: loader 0.5s 2s;
  transform: translateY(0);

  .image {
    @media (min-width: 976px) {
      width: 50vw;
    }
    animation: svg-loader 2s 0.3s;
    transform: translateY(-100vh);
  }
}

@keyframes svg-loader {
  0% {
    transform: translateY(100vh) scale(0.5);
  }
  40% {
    transform: translateY(0) scale(1);
  }
  60% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(-100vh) scale(0.5);
  }
}

@keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}

.utils-menu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.swing {
  animation: swing 1s linear infinite both;
}
</style>

<template>
  <div>
    <v-btn
      class="border-shaped ml-2 yellow--bg"
      depressed
      @click="toggleDialog"
    >
      <v-icon v-if="item">mdi-pencil</v-icon>
      <v-icon v-else>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-form
        ref="formEditFinitions"
        v-model="valid"
        @submit.prevent="submitFinition"
      >
        <v-card shaped elevation="24">
          <v-card-title class="text-h4 text-lg-h3 font-weight-bold">
            {{ item ? 'Éditer Finition' : 'Ajouter Finition' }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="name"
              label="Libellé"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="en_name"
              label="Libellé Anglais"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_height_interieur"
              label="Hauteur minimum intérieure"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_height_interieur"
              label="Hauteur maximum intérieure"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_height_couverture"
              label="Hauteur minimum couverture"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_height_couverture"
              label="Hauteur maximum couverture"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_width_interieur"
              label="Largeur minimum intérieure"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_width_interieur"
              label="Largeur maximum intérieure"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_width_couverture"
              label="Largeur minimum couverture"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_width_couverture"
              label="Largeur maximum couverture"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_page_count"
              label="Nombre de pages minimum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_page_count"
              label="Nombre de pages maximum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_quantity"
              label="Quantité minimum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_quantity"
              label="Quantité maximum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_epaisseur"
              label="Epaisseur minimum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_epaisseur"
              label="Epaisseur maximum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_bords_perdus"
              label="Bords perdus minimum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_bords_perdus"
              label="Bords perdus maximum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_pourcentage_bords_perdus"
              label="Pourcentage bords perdus maximum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="border-shaped yellow--bg ml-auto"
              type="submit"
              :loading="loading"
              depressed
              large
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/utils'
export default {
  props: ['item'],
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      name: this.item ? this.item.name : '',
      en_name: this.item ? this.item.en_name : '',
      enabled: this.item ? this.item.enabled : true,
      min_height_interieur: this.item ? this.item.min_height_interieur : 0,
      max_height_interieur: this.item ? this.item.max_height_interieur : 0,
      min_height_couverture: this.item ? this.item.min_height_couverture : 0,
      max_height_couverture: this.item ? this.item.max_height_couverture : 0,
      min_width_interieur: this.item ? this.item.min_width_interieur : 0,
      max_width_interieur: this.item ? this.item.max_width_interieur : 0,
      min_width_couverture: this.item ? this.item.min_width_couverture : 0,
      max_width_couverture: this.item ? this.item.max_width_couverture : 0,
      min_page_count: this.item ? this.item.min_page_count : 0,
      max_page_count: this.item ? this.item.max_page_count : 0,
      min_quantity: this.item ? this.item.min_quantity : 0,
      max_quantity: this.item ? this.item.max_quantity : 0,
      min_epaisseur: this.item ? this.item.min_epaisseur : 0,
      max_epaisseur: this.item ? this.item.max_epaisseur : 0,
      min_bords_perdus: this.item ? this.item.min_bords_perdus : 0,
      max_bords_perdus: this.item ? this.item.max_bords_perdus : 0,
      max_pourcentage_bords_perdus: this.item
        ? this.item.max_pourcentage_bords_perdus
        : 0,
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
    },
    submitFinition() {
      this.$refs.formEditFinitions.validate()
      if (this.valid) {
        this.loading = true
        const data = {
          name: this.name,
          en_name: this.en_name,
          enabled: this.enabled,
          min_height_interieur: this.min_height_interieur
            ? this.min_height_interieur
            : null,
          max_height_interieur: this.max_height_interieur
            ? this.max_height_interieur
            : null,
          min_height_couverture: this.min_height_couverture
            ? this.min_height_couverture
            : null,
          max_height_couverture: this.max_height_couverture
            ? this.max_height_couverture
            : null,
          min_width_interieur: this.min_width_interieur
            ? this.min_width_interieur
            : null,
          max_width_interieur: this.max_width_interieur
            ? this.max_width_interieur
            : null,
          min_width_couverture: this.min_width_couverture
            ? this.min_width_couverture
            : null,
          max_width_couverture: this.max_width_couverture
            ? this.max_width_couverture
            : null,
          min_page_count: this.min_page_count ? this.min_page_count : null,
          max_page_count: this.max_page_count ? this.max_page_count : null,
          min_quantity: this.min_quantity ? this.min_quantity : null,
          max_quantity: this.max_quantity ? this.max_quantity : null,
          min_epaisseur: this.min_epaisseur ? this.min_epaisseur : null,
          max_epaisseur: this.max_epaisseur ? this.max_epaisseur : null,
          min_bords_perdus: this.min_bords_perdus
            ? this.min_bords_perdus
            : null,
          max_bords_perdus: this.max_bords_perdus
            ? this.max_bords_perdus
            : null,
          max_pourcentage_bords_perdus: this.max_pourcentage_bords_perdus
            ? this.max_pourcentage_bords_perdus
            : null,
        }

        if (this.item) {
          axios
            .put(
              process.env.VUE_APP_API_URL + '/admin/finitions/' + this.item.id,
              data,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
                },
              }
            )
            .then(() => {
              EventBus.$emit('refreshfinitions')
              this.dialog = false
              this.loading = false
              EventBus.$emit('notif', 'Finition modifiée', 'success')
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          axios
            .post(process.env.VUE_APP_API_URL + '/admin/finitions', data, {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              },
            })
            .then(() => {
              EventBus.$emit('refreshfinitions')
              this.dialog = false
              this.loading = false
              EventBus.$emit('notif', 'Finition modifiée', 'success')
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-btn
      class="border-shaped ml-2 mb-2 yellow--bg"
      depressed
      @click="toggleDialog"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-form ref="formEditOrder" v-model="valid" @submit.prevent="editLine">
        <v-card shaped elevation="24">
          <v-card-title class="text-h4 text-lg-h3 font-weight-bold">
            Editer ligne commande
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="quantity"
              type="number"
              :label="$t('order.line.quantity')"
              calc-icon=""
              class="border-shaped"
              outlined
              :use-grouping="false"
              :rules="quantityRules"
              :min="minQuantity"
              :max="maxQuantity"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="address"
                  label="Adresse"
                  class="border-shaped"
                  outlined
                  clearable
                  :items="addresses"
                  :rules="addressRules"
                  :item-text="
                    (item) =>
                      (item.pinned ? '⭐' : '') +
                      ' ' +
                      item.address_full +
                      ' - ' +
                      item.country.name +
                      ' ' +
                      (item.complement ? item.complement : '') +
                      ' ' +
                      (item.contact ? item.contact : '') +
                      ' ' +
                      (item.raison_sociale ? item.raison_sociale : '') +
                      ' ' +
                      (item.email ? item.email : '')
                  "
                  return-object
                >
                  <template #item="{ item }">
                    <v-list-item-content class="text-caption">
                      <strong
                        >{{ item.address_full }}
                        {{ item.pinned ? '⭐' : '' }}</strong
                      >
                      <div class="overflow-y-auto">
                        <div>
                          <strong>{{ $t('address.contact') }}</strong
                          >: {{ item.contact }}
                        </div>
                        <div>
                          <strong>{{ $t('address.raison_sociale') }}</strong
                          >:
                          <span v-if="item.raison_sociale">
                            {{ item.raison_sociale }}</span
                          >
                          <span v-else class="grey--text font-italic">
                            Pas de raison sociale
                          </span>
                        </div>
                        <div>
                          <strong>{{ $t('address.address') }}</strong> :
                          {{ item.address }}
                        </div>
                        <div v-if="item.complement">
                          <strong>{{ $t('address.complement') }}</strong> :
                          {{ item.complement }}
                        </div>
                        <div>
                          <strong>{{ $t('address.postal_code') }}</strong> :
                          {{ item.code_postal }}
                        </div>
                        <div>
                          <strong>{{ $t('address.city') }}</strong> :
                          {{ item.city }}
                        </div>
                        <div>
                          <strong>{{ $t('address.country') }}</strong> :
                          {{
                            $i18n.locale === 'fr'
                              ? item.country.name
                              : item.country.en_name
                          }}
                        </div>
                        <div v-if="item.phone_mobile">
                          <strong>{{ $t('address.phone.mobile') }}</strong> :
                          {{ item.phone_mobile }}
                        </div>
                        <div v-if="item.phone_fix">
                          <strong>{{ $t('address.phone.fix') }}</strong> :
                          {{ item.phone_fix }}
                        </div>
                        <div>
                          <strong>{{ $t('global.email') }}</strong> :
                          {{ item.email }}
                        </div>
                        <div>
                          <strong>{{ $t('address.type') }}</strong> :
                          {{
                            $i18n.locale === 'fr'
                              ? item.address_type.name
                              : item.address_type.en_name
                          }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-textarea
              v-model="comment"
              label="Commentaire"
              outlined
              counter
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="border-shaped yellow--bg ml-auto"
              type="submit"
              :loading="loading"
              depressed
              large
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script lang="js">

import axios from 'axios'
import { EventBus } from '@/utils'

export default {
  name: 'LinesOrderAdminEdit',
  props: ['line'],
  data() {
    return {
      opened: false,
      dialog: false,
      valid: false,
      loading: false,
      quantity: this.line.quantity,
      address: this.line.address,
      comment: this.line.comment,
      addresses: [],
      quantityRules: [
        () => {
          if (this.quantity < this.minQuantity) {
            return this.$t('quantity_min', [this.minQuantity])
          }
          if (this.quantity > this.maxQuantity) {
            return this.$t('quantity_max', [this.maxQuantity])
          }
          return true
        }
      ],
      addressRules: [v => v !== null || this.$t('form.required.address.address')]
    }
  },
  computed: {
    minQuantity() {
      return this.line.product.finition?.id === 2
        ? this.line.product.finition?.min_quantity
        : this.line.product.impression?.id === 1
          ? Math.max(this.line.product.papier_couverture?.papier.min_exemplaires, this.line.product.papier_interieur?.papier.min_exemplaires) || this.line.product.finition?.min_quantity
          : Math.max(this.line.product.papier_couverture?.papier.min_exemplaires_couleur, this.line.product.papier_interieur?.papier.min_exemplaires_couleur) || this.line.product.finition?.min_quantity
    },
    maxQuantity() {
      return this.line.product.finition?.id === 2
        ? this.line.product.finition?.max_quantity
        : this.line.product.impression?.id === 1
          ? Math.min(this.line.product.papier_couverture?.papier.max_exemplaires, this.line.product.papier_interieur?.papier.max_exemplaires) || this.line.product.finition?.max_quantity
          : Math.min(this.line.product.papier_couverture?.papier.max_exemplaires_couleur, this.line.product.papier_interieur?.papier.max_exemplaires_couleur) || this.line.product.finition?.max_quantity
    },
  },
  mounted() {
    EventBus.$on('refreshaddresses', () => {
      this.getAddresses()
    })
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
      if (this.dialog && !this.opened) {
        this.getAddresses()
        this.opened = true
      }
    },
    getAddresses() {
      this.loading = true
      axios
        .get(process.env.VUE_APP_API_URL + '/admin/addresses', {
          params: {
            line_id: this.line.id
          },
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.addresses = response.data.items
          this.loading = false
        })
    },
    editLine(e) {
      e.preventDefault()

      if (this.this.$refs.formEditOrder.validate()) {
        this.loading = true
        const data = {
          quantity: this.quantity,
          address_id: this.address.id,
          comment: this.comment
        }
        axios
          .put(
            process.env.VUE_APP_API_URL + '/admin/lines/' + this.line.id,
            data,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`
              }
            })
          .then(() => {
            EventBus.$emit('refreshproducts')
            EventBus.$emit('refreshorders')
            EventBus.$emit('refreshlines')
            this.dialog = false
            EventBus.$emit('notif', 'Ligne modifiée', 'success')
          })
          .catch((e) => {
            EventBus.$emit('notif', e.response.data.message, 'error')
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

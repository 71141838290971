<template>
  <section class="simulation">
    <page-title :title="$t('title')" :breadcrumbs="items"></page-title>
    <v-dialog v-model="dialog" max-width="400" class="border-shaped">
      <v-sheet class="border-shaped">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-lg-h5">
              {{ $t('title') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense color="transparent">
          <v-list-item>
            <v-list-item-title>{{ $t('product.weight') }} :</v-list-item-title>
            <v-list-item-action>
              <v-list-item-action-text>
                {{
                  poids
                    ? Math.round(parseFloat(poids)) + 'g'
                    : $t('unavailable')
                }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="mt-n5">
            <v-list-item-title
              >{{ $t('product.thickness') }} :</v-list-item-title
            >
            <v-list-item-action>
              <v-list-item-action-text>
                {{
                  epaisseur
                    ? Math.round(epaisseur * 2) / 2 + 'mm'
                    : $t('unavailable')
                }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="mt-n5">
            <v-list-item-title
              >{{ $t('order.price.production') }} :</v-list-item-title
            >
            <v-list-item-action>
              <v-list-item-action-text>
                {{
                  prix_fabrication
                    ? prix_fabrication.toFixed(2) + '€'
                    : $t('unavailable')
                }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="mt-n5">
            <v-list-item-title
              >{{ $t('order.price.packaging') }} :</v-list-item-title
            >
            <v-list-item-action>
              <v-list-item-action-text>
                {{
                  prix_emballage
                    ? prix_emballage.toFixed(2) + '€'
                    : $t('unavailable')
                }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="mt-n5">
            <v-list-item-title
              >{{ $t('order.price.shipping') }} :</v-list-item-title
            >
            <v-list-item-action>
              <v-list-item-action-text>
                {{
                  prix_livraison
                    ? prix_livraison.toFixed(2) + '€'
                    : $t('unavailable')
                }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="mt-n5">
            <v-list-item-title>{{ $t('order.price.ht') }} :</v-list-item-title>
            <v-list-item-action>
              <v-list-item-action-text
                >{{ prix_ht.toFixed(2) }}€</v-list-item-action-text
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="mt-n5">
            <v-list-item-title>{{ $t('order.price.ttc') }}:</v-list-item-title>
            <v-list-item-action>
              <v-list-item-action-text
                >{{ prix_ttc.toFixed(2) }}€</v-list-item-action-text
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                large
                block
                class="yellow--bg border-shaped mb-5"
                depressed
                @click="startCreateProduct"
                >{{ $t('go_to_creation') }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-dialog>
    <v-form ref="formSim" v-model="valid" @submit.prevent="getPrice">
      <v-lazy transition="slide-y-transition">
        <v-container v-if="errors">
          <v-row>
            <v-col>
              <v-list dense>
                <v-subheader>{{ $t('global.errors') }} :</v-subheader>
                <v-list-item
                  v-for="(e, i) in errors"
                  :key="i"
                  class="red--text text-justify"
                >
                  <v-list-item-icon>
                    <v-icon class="red--text">mdi-alert-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ e }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-lazy>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="page_count"
              type="number"
              :label="$t('product.page_count')"
              calc-icon=""
              class="border-shaped"
              outlined
              :rules="page_countRules"
              min="1"
            ></v-text-field>
            <v-text-field
              v-model="quantity"
              type="number"
              :label="$t('order.line.quantity')"
              calc-icon=""
              class="border-shaped"
              outlined
              :use-grouping="false"
              :rules="quantityRules"
              :min="minQuantity"
              :max="maxQuantity"
            ></v-text-field>
            <v-select
              v-model="address_type"
              :label="$t('address.type')"
              outlined
              class="border-shaped"
              return-object
              :items="address_types_items"
              :item-text="
                (item) => ($i18n.locale === 'fr' ? item.name : item.en_name)
              "
              required
              :rules="address_typesRules"
            ></v-select>
            <v-select
              v-model="country"
              :items="countries"
              item-text="name"
              item-value="iso"
              :label="$t('address.country')"
              return-object
              outlined
              class="border-shaped"
            >
              <template #selection="{ item }">
                <v-list-item-icon>
                  <CountryFlag :country="item.iso" rounded class="mt-n2" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $i18n.locale === 'fr' ? item.name : item.en_name
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template #item="{ item }">
                <v-list-item-icon>
                  <CountryFlag :country="item.iso" rounded class="mt-n2" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $i18n.locale === 'fr' ? item.name : item.en_name
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="category"
              :items="category_items"
              :rules="categoryRules"
              class="border-shaped"
              :label="$t('product.category')"
              :item-text="
                (item) =>
                  $i18n.locale === 'fr'
                    ? item.name
                    : item.en_name +
                      ' - ' +
                      $t('order.price.tva') +
                      ' : ' +
                      item.tva +
                      '%'
              "
              outlined
              required
              return-object
            >
              <template #selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} - TVA : {{ item.tva }}%</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <template #item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} - TVA : {{ item.tva }}%</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-select>
            <v-select
              v-model="finition"
              :label="$t('product.finition')"
              outlined
              class="border-shaped"
              return-object
              :items="finition_items"
              :item-text="
                (item) => ($i18n.locale === 'fr' ? item.name : item.en_name)
              "
              required
              :rules="finitionRules"
            ></v-select>
            <v-select
              v-model="impression"
              :label="$t('product.impression')"
              outlined
              class="border-shaped"
              return-object
              :items="impression_items_filtered"
              :item-text="
                (item) => ($i18n.locale === 'fr' ? item.name : item.en_name)
              "
              required
              :rules="impressionRules"
              @change="updateImpression"
            ></v-select>
            <v-switch
              v-model="enableFormat"
              :label="$t('product.custom_format')"
              class="mt-n1 mr-2"
              @change="updateFormat"
            ></v-switch>
            <v-select
              v-if="!enableFormat"
              v-model="format"
              :label="$t('product.format')"
              outlined
              class="border-shaped"
              :items="format_items"
              item-text="name"
              return-object
              required
              :rules="formatRules"
            ></v-select>
            <v-input v-if="enableFormat">
              <v-text-field
                v-model="width"
                :label="$t('product.width')"
                outlined
                required
                :rules="widthRules"
                class="border-shaped mr-3"
                type="number"
                :hint="
                  $t('form.hint.product.width', [
                    finition?.min_width_interieur,
                    finition?.max_width_interieur,
                  ])
                "
                persistent-hint
              ></v-text-field>
              <v-text-field
                v-model="height"
                :label="$t('product.height')"
                outlined
                required
                :rules="heightRules"
                class="border-shaped ml-3"
                type="number"
                :hint="
                  $t('form.hint.product.height', [
                    finition?.min_height_interieur,
                    finition?.max_height_interieur,
                  ])
                "
                persistent-hint
              ></v-text-field>
            </v-input>
            <v-lazy v-if="enableCouverture" transition="slide-y-transition">
              <div>
                <v-select
                  v-model="papier_couverture"
                  :label="$t('product.cover_paper')"
                  :item-text="
                    (item) =>
                      $i18n.locale === 'fr'
                        ? item.papier.name
                        : item.papier.en_name
                  "
                  outlined
                  return-object
                  :rules="papierCouvertureRules"
                  required
                  class="border-shaped"
                  :items="papier_couverture_items"
                ></v-select>
                <v-select
                  v-model="pelliculage"
                  :label="$t('product.lamination')"
                  outlined
                  class="border-shaped"
                  return-object
                  :items="pelliculage_items"
                  :item-text="
                    (item) => ($i18n.locale === 'fr' ? item.name : item.en_name)
                  "
                  :rules="pelliculageRules"
                ></v-select>
              </div>
            </v-lazy>
            <v-switch
              v-if="!!impression ? impression.id === 2 : false"
              v-model="recto_verso"
              :label="$t('product.recto_verso')"
              class="mt-n3"
            ></v-switch>
            <v-select
              v-model="papier_interieur"
              :label="$t('product.inner_paper')"
              outlined
              class="border-shaped"
              return-object
              :rules="papierInterieurRules"
              required
              :items="papier_interieur_items"
              :item-text="
                (item) =>
                  $i18n.locale === 'fr' ? item.papier.name : item.papier.en_name
              "
            ></v-select>
            <v-lazy v-if="finition?.id !== 2" transition="slide-y-transition">
              <v-switch
                v-model="bords_perdus"
                :label="$t('product.lost_edges')"
                class="mt-n3"
              ></v-switch>
            </v-lazy>
            <v-lazy v-if="finition?.id === 2" transition="slide-y-transition">
              <v-switch
                v-model="enableCouverture"
                :label="$t('enable_couverture')"
                class="mt-n3"
              ></v-switch>
            </v-lazy>
            <v-btn
              type="submit"
              large
              class="yellow--bg border-shaped float-right mb-5"
              depressed
              :loading="loading"
            >
              {{ $t('calc_price') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </section>
</template>

<script lang="js">

import axios from 'axios'
import PageTitle from '@/components/info/PageTitle'
import { EventBus } from '@/utils'
import isNumeric from 'validator/lib/isNumeric'

export default {
  name: 'SimulationTest',
  metaInfo: {
    title: 'Rapidbook - Simulation Test',
  },
  components: { PageTitle },
  props: [],
  data() {
    return {
      prix_livraison: 0,
      prix_emballage: 0,
      prix_fabrication: 0,
      prix_ht: 0,
      prix_ttc: 0,
      epaisseur: 0,
      poids: 0,
      submitted: false,
      valid: false,
      dialog: false,
      errors: null,
      loading: false,
      quantity: 1,
      page_count: 256,
      finition: null,
      category: null,
      impression: null,
      format: null,
      enableFormat: false,
      width: null,
      height: null,
      papier_couverture: null,
      enableCouverture: true,
      pelliculage: null,
      recto_verso: false,
      papier_interieur: null,
      bords_perdus: false,
      address_type: null,
      country: null,
      countries: [
      ],
      finition_items: [],
      category_items: [],
      address_types_items: [],
      impression_items: [],
      format_items: [],
      papier_couverture_items: [],
      papier_couverture_items_full: [],
      pelliculage_items: [],
      papier_interieur_items: [],
      papier_interieur_items_full: [],
      finitionRules: [v => v !== null || this.$t('form.required.product.finition')],
      categoryRules: [v => v !== null || this.$t('form.required.product.category')],
      address_typesRules: [v => v !== null || this.$t('form.required.address.type')],
      impressionRules: [v => v !== null || this.$t('form.required.product.impression')],
      formatRules: [() => (!this.enableFormat && this.format !== null) || this.$t('form.required.product.format')],
      widthRules: [
        () => {
          if (!this.enableFormat) {
            return true
          }
          if (!this.width) {
            return this.$t('form.required.product.width')
          }

          if (!isNumeric('' + this.width || '')) {
            return this.$t('form.required.product.width')
          }


          if (parseFloat(this.width) < parseFloat(this.finition?.min_width_interieur) || parseFloat(this.width) > parseFloat(this.finition?.max_width_interieur))
            return this.$t('width_limit', [parseFloat(this.finition?.min_width_interieur), parseFloat(this.finition?.max_width_interieur)])

          return true
        }
      ],
      heightRules: [
        () => {
          if (!this.enableFormat) {
            return true
          }

          if (!this.height) {
            return this.$t('form.required.product.height')
          }

          if (!isNumeric('' + this.height || '')) {
            return this.$t('form.required.product.height')
          }

          if (parseFloat(this.height) < parseFloat(this.finition?.min_height_interieur) || parseFloat(this.height) > parseFloat(this.finition?.max_height_interieur))
            return this.$t('height_limit', [parseFloat(this.finition?.min_height_interieur), parseFloat(this.finition?.max_height_interieur)])

          return true
        }
      ],
      papierCouvertureRules: [v => {
        if (v !== null) {
          return v.papier.impression === null || v.papier.impression.id === this.impression.id || this.$t('form.invalid.product.paper.cover')
        }
        return this.$t('form.required.product.paper.cover')
      }],
      pelliculageRules: [v => v !== null || this.$t('form.required.product.lamination')],
      rectoVersoRules: [],
      papierInterieurRules: [v => {
        if (v !== null) {
          return v.papier.impression === null || v.papier.impression.id === this.impression.id || this.$t('form.invalid.product.paper.inner')
        }
        return this.$t('form.required.product.paper.inner')
      }],
      quantityRules: [
        () => {
          if (this.quantity < this.minQuantity) {
            return this.$t('quantity_min', [this.minQuantity])
          }
          if (this.quantity > this.maxQuantity) {
            return this.$t('quantity_max', [this.maxQuantity])
          }
          return true
        }
      ],
      page_countRules: [
        () => {
          if (this.finition?.id === 2) {
            if (this.page_count < 8) {
              return this.$t('page_count_min_pique')
            }
            if (this.page_count > 76) {
              return this.$t('page_count_max_pique')
            }
            if (this.page_count % 4 !== 0) {
              return this.$t('page_count_by_4')
            }
          }
          else {
            if (this.page_count < 0) {
              return this.$t('page_count_min')
            }
            if (this.page_count % 2 !== 0) {
              return this.$t('page_count_odd')
            }
          }
          return true
        }],
      bordsPerdusRules: [],
      items: [
        {
          text: 'Rapidbook',
          disabled: false,
          to: '/'
        },
        {
          text: 'Dashboard',
          disabled: false,
          to: 'dashboard'
        },
        {
          text: 'Simulation Test',
          disabled: true,
          to: ''
        }
      ]
    }
  },
  computed: {
    impression_items_filtered() {
      return this.impression_items.filter((i) => {
        return (this.finition?.id === 2 && i.id === 2) || this.finition?.id === 1
      })
    },
    minQuantity() {
      return this.finition?.id === 2
        ? this.finition?.min_quantity
        : this.impression?.id === 1
          ? Math.max(this.papier_couverture?.papier.min_exemplaires, this.papier_interieur?.papier.min_exemplaires) || this.finition?.min_quantity
          : Math.max(this.papier_couverture?.papier.min_exemplaires_couleur, this.papier_interieur?.papier.min_exemplaires_couleur) || this.finition?.min_quantity
    },
    maxQuantity() {
      return this.finition?.id === 2
        ? this.finition?.max_quantity
        : this.impression?.id === 1
          ? Math.min(this.papier_couverture?.papier.max_exemplaires, this.papier_interieur?.papier.max_exemplaires) || this.finition?.max_quantity
          : Math.min(this.papier_couverture?.papier.max_exemplaires_couleur, this.papier_interieur?.papier.max_exemplaires_couleur) || this.finition?.max_quantity
    },
  },
  watch: {
    finition: function () {
      if (this.finition?.id === 2) {
        this.impression = this.impression_items_filtered[0]
        this.bords_perdus = true
      }
      else {
        this.enableCouverture = true
      }
    },
    impression: function () {
      this.updateImpression()
    },
    enableCouverture: function () {
      if (this.enableCouverture) {
        this.papier_couverture = this.papier_couverture_items[0]
      }
      else {
        this.papier_couverture = null
        this.pelliculage = this.pelliculage_items[this.pelliculage_items.length - 1]
      }
    }
  },
  mounted() {
    this.getCategory()
    this.getCountries()
    this.getFinitions()
    this.getAddressType()
    this.getFormats()
    this.getPelliculage()
    this.getPapierCouverture()
    this.getPapierInterieur()
    this.getImpressions()
  },
  methods: {
    startCreateProduct() {
      this.$router.push({
        name: 'Library',
        query: {
          dialog: true,
          category: this.category.id,
          finition: this.finition.id,
          impression: this.impression.id,
          width: this.format ? this.format.width : this.width,
          height: this.format ? this.format.height : this.height,
          papier_couverture: this.papier_couverture?.id,
          pelliculage: this.pelliculage.id,
          papier_interieur: this.papier_interieur.id,
          bords_perdus: this.bords_perdus,
          recto_verso: this.recto_verso
        }
      })
    },
    getCountries() {
      axios
        .get(process.env.VUE_APP_API_URL + '/country', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.countries = response.data.countries
          this.country = this.countries[0]
        })
    },
    getFinitions() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/finition',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.finition_items = r.data.finitions
          !this.finition ? this.finition = this.finition_items[0] : null
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    getAddressType() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/address_type',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.address_types_items = r.data.address_types
          !this.address_type ? this.address_type = this.address_types_items[0] : null
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    getCategory() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/category',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.category_items = r.data.categories
          this.category = this.category_items[0]
        })
        .catch(() => {
        })
    },
    getImpressions() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/impression',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.impression_items = r.data.impressions
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    updateImpression() {
      if (this.impression === null) {
        this.papier_couverture_items = []
        this.papier_couverture = { id: 0, name: this.$t('choose_impression') }

        this.papier_interieur_items = []
        this.papier_interieur = { id: 0, name: this.$t('choose_impression') }
        return false
      }

      this.papier_couverture_items = this.papier_couverture_items_full.filter((p) => {
        return p.papier.impression === null || p.papier.impression.id === this.impression.id
      })
      this.papier_interieur_items = this.papier_interieur_items_full.filter((p) => {
        return p.papier.impression === null || p.papier.impression.id === this.impression.id
      })

      !this.papier_couverture ? this.papier_couverture = this.papier_couverture_items[0] : null
      !this.papier_interieur ? this.papier_interieur = this.papier_interieur_items[0] : null
    },
    getFormats() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/format',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.format_items = r.data.formats
          !this.format ? this.format = this.format_items[0] : null
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    updateFormat() {
      if (this.enableFormat) {
        this.format = null
      } else {
        this.width = null
        this.height = null
      }
    },
    getPelliculage() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/pelliculage',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.pelliculage_items = r.data.pelliculages
          !this.pelliculage ? this.pelliculage = this.pelliculage_items[0] : null
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    getPapierCouverture() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/papier_couverture',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.papier_couverture_items_full = r.data.papiers_couverture
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    getPapierInterieur() {
      axios
        .get(
          process.env.VUE_APP_API_URL + '/papier_interieur',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
        .then((r) => {
          this.papier_interieur_items_full = r.data.papiers_interieur
        })
        .catch(() => {
          EventBus.$emit('notif', this.$t('global.error'), 'error')
        })
    },
    getPrice(e) {
      e.preventDefault()
      if (this.$refs.formSim.validate()) {
        this.errors = null
        this.loading = true
        const formData = new FormData()
        formData.append('finition_id', this.finition.id)
        formData.append('category_id', this.category.id)
        formData.append('impression_id', this.impression.id)
        this.enableCouverture ? formData.append('papier_couverture_id', this.papier_couverture.id) : null
        formData.append('pelliculage_id', this.pelliculage.id)
        formData.append('recto_verso', this.recto_verso)
        formData.append('papier_interieur_id', this.papier_interieur.id)
        this.bords_perdus ? formData.append('is_bords_perdus', this.bords_perdus) : null
        formData.append('quantity', this.quantity)
        formData.append('address_type_id', this.address_type.id)
        formData.append('country_id', this.country.id)
        formData.append('page_count', this.page_count)

        if (this.format !== null) {
          formData.append('format_id', this.format.id)
        } else if (this.width !== null && this.height !== null) {
          formData.append('width', this.width)
          formData.append('height', this.height)
        }
        axios
          .post(
            process.env.VUE_APP_API_URL + '/simulation',
            formData,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`
              }
            })
          .then((r) => {
            this.prix_fabrication = r.data.prices.prix_fabrication
            this.prix_livraison = r.data.prices.prix_livraison
            this.prix_emballage = r.data.prices.prix_emballage
            this.prix_ht = r.data.prices.prix_ht
            this.prix_ttc = r.data.prices.prix_ttc
            this.epaisseur = r.data.prices.epaisseur
            this.poids = r.data.prices.poids
            this.loading = false
            this.dialog = true
          })
          .catch((e) => {
            this.loading = false
            EventBus.$emit('notif', e.response.data.message, 'error')
            this.errors = e.response.data.errors
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.simulation {
  min-height: calc(100vh - 64px);
}
</style>

<i18n lang="json">
{
  "fr": {
    "title": "Simulation Tarifaire",
    "unavailable": "Non disponible",
    "go_to_creation": "Passer à la création de produit",
    "choose_impression": "Choisissez un mode d'impression",
    "quantity_min": "Quantité supérieure à {0}",
    "quantity_min_pique": "Quantité minimum supérieure à 50",
    "quantity_max": "Quantité inférieure ou égal à 2000",
    "page_count_min": "Nombre de pages supérieur à 0",
    "page_count_min_pique": "Nombre de pages supérieur à 8",
    "page_count_max_pique": "Nombre de pages inférieure à 76 (76 + 4 (couverture))",
    "page_count_odd": "Nombre de pages positif ou divisible par 2",
    "page_count_by_4": "Nombre de pages positif ou divisible par 4",
    "calc_price": "Simuler",
    "width_limit": "La largeur doit être comprise entre {0} et {1} mm",
    "height_limit": "La hauteur doit être comprise entre {0} et {1} mm",
    "enable_couverture": "Activer/Désactiver la couverture"
  },
  "en": {
    "title": "Pricing Simulation",
    "unavailable": "Unavailable",
    "go_to_creation": "Go to product create form",
    "choose_impression": "Choose a printing mode",
    "quantity_min": "Quantity above {0}",
    "quantity_min_pique": "Quantity above 50",
    "quantity_max": "Quantity equal or less than 2000",
    "page_count_min": "Number of pages above 0",
    "page_count_min_pique": "Number of pages above 8",
    "page_count_max_pique": "Number of pages below 76 (76 + 4 (cover))",
    "page_count_odd": "Number of pages positive or divisible by 2",
    "page_count_by_4": "Number of pages positive or divisible by 4",
    "calc_price": "Simulate",
    "width_limit": "The width must be between {0} and {1} mm",
    "height_limit": "The height must be between {0} and {1} mm",
    "enable_couverture": "Enable/Disable cover"
  }
}
</i18n>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-textarea',{attrs:{"label":"Message d'alerte","placeholder":"Aucun message d'alerte","outlined":"","counter":""},model:{value:(_vm.info.message),callback:function ($$v) {_vm.$set(_vm.info, "message", $$v)},expression:"info.message"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('div',[_c('v-switch',{attrs:{"label":"Activé/Désactivé"},on:{"change":_vm.toggleInfo},model:{value:(_vm.info.enabled),callback:function ($$v) {_vm.$set(_vm.info, "enabled", $$v)},expression:"info.enabled"}}),_c('v-btn',{staticClass:"border-shaped yellow--bg",attrs:{"loading":_vm.loading_info,"depressed":"","large":"","block":""},on:{"click":_vm.updateInfo}},[_vm._v("Enregistrer")])],1)])],1)],1)],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',[_c('v-col',[_c('v-card-title',[_c('div',[_vm._v("Derniers produits non validés")]),_c('v-spacer'),_c('v-text-field',{staticClass:"border-shaped",attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search_products),callback:function ($$v) {_vm.search_products=$$v},expression:"search_products"}}),_c('v-btn',{staticClass:"border-shaped yellow--bg ml-2",attrs:{"depressed":"","x-large":"","loading":_vm.loading_last_products},on:{"click":_vm.getLastProducts}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"border-shaped elevation-1",style:(_vm.last_products.length > 0 ? 'white-space: nowrap' : ''),attrs:{"loading":_vm.loading_last_products,"search":_vm.search_products,"dense":"","headers":[
            { text: 'Action', value: 'action' },
            { text: 'Activé/Désactivé', value: 'enabled' },
            { text: 'Id', align: 'start', value: 'id' },
            { text: 'Uid', value: 'uid' },
            { text: 'ISBN', value: 'isbn' },
            { text: 'Référence', value: 'reference' },
            { text: 'Titre', value: 'title' },
            { text: 'Auteur', value: 'author' },
            { text: 'Nombre de pages', value: 'page_count' },
            { text: 'Epaisseur', value: 'epaisseur' },
            { text: 'Poids', value: 'weight' },
            { text: 'Format', value: 'format.name' },
            { text: 'Impression', value: 'impression.name' },
            { text: 'Pelliculage', value: 'pelliculage.name' },
            {
              text: 'Papier couverture',
              value: 'papier_couverture.papier.name',
            },
            {
              text: 'Papier interieur',
              value: 'papier_interieur.papier.name',
            },
            { text: 'Etat', value: 'f1.name' },
            { text: 'Envoyé au serveur', value: 'f2' },
            { text: 'Acheve', value: 'acheve.name' },
            { text: 'Finition', value: 'finition.name' },
            { text: 'Version', value: 'version' },
            { text: 'Mis à jour le', value: 'updated_at' },
            { text: 'Crée le', value: 'created_at' },
            { text: 'Utilisateur', value: 'user.username' },
          ],"sort-by":['updated_at'],"sort-desc":[true],"items":_vm.last_products,"footer-props":{
            'items-per-page-options': [
              5, 10, 15, 30, 50, 100, 200, 300, 500, 1000, -1,
            ],
            itemsPerPageText: 'Lignes par page',
          }},scopedSlots:_vm._u([{key:`item.weight`,fn:function({ item }){return [_vm._v(_vm._s(Math.round(parseFloat(item.weight)))+" g")]}},{key:`item.epaisseur`,fn:function({ item }){return [_vm._v(_vm._s(Math.round(item.epaisseur * 2) / 2)+" mm")]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"py-1 d-flex"},[_c('ProductsAdminDetail',{attrs:{"product":item}}),_c('ProductAdminEdit',{staticClass:"ml-1",attrs:{"product":item}}),_c('v-btn',{staticClass:"border-shaped ml-1",attrs:{"depressed":"","color":"red"},on:{"click":function($event){return _vm.deleteProduct(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.created_at).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.updated_at).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.enabled`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center fill-height",style:(item.enabled
                  ? 'background-color: #4caf50'
                  : 'background-color: #f44336')},[_c('v-switch',{staticClass:"mb-n4 mt-1",attrs:{"color":"white"},on:{"click":function($event){return _vm.toggleProduct(item.id)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:`item.f2`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.f2 === 1 ? 'Oui' : 'Non')+" ")]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',[_c('v-col',[_c('v-card-title',[_c('div',[_vm._v("Dernières commandes passées")]),_c('v-spacer'),_c('v-text-field',{staticClass:"border-shaped",attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search_orders),callback:function ($$v) {_vm.search_orders=$$v},expression:"search_orders"}}),_c('v-btn',{staticClass:"border-shaped yellow--bg ml-2",attrs:{"depressed":"","x-large":"","loading":_vm.loading_last_orders},on:{"click":_vm.getLastOrders}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"border-shaped elevation-1",style:(_vm.last_orders.length > 0 ? 'white-space: nowrap' : ''),attrs:{"loading":_vm.loading_last_orders,"search":_vm.search_orders,"dense":"","headers":[
            { text: 'Action', value: 'action' },
            { text: 'Activé/Désactivé', value: 'enabled' },
            { text: 'Id', align: 'start', value: 'id' },
            { text: 'Uid', align: 'start', value: 'uid' },
            { text: 'Etat', value: 'f1.name' },
            { text: 'Prix Fabrication', value: 'prix_fabrication' },
            { text: 'Prix Livraison', value: 'prix_livraison' },
            { text: 'Prix Emballage', value: 'prix_emballage' },
            { text: 'Prix HT', value: 'prix_ht' },
            { text: 'Prix TTC', value: 'prix_ttc' },
            { text: 'Crée le', value: 'created_at' },
            { text: 'Mis à jour le', value: 'updated_at' },
            { text: 'Utilisateur', value: 'user.username' },
            { text: 'Lignes de commande', value: 'lignes_commande' },
          ],"sort-by":['updated_at'],"sort-desc":[true],"items":_vm.last_orders,"footer-props":{
            'items-per-page-options': [
              5, 10, 15, 30, 50, 100, 200, 300, 500, 1000, -1,
            ],
            itemsPerPageText: 'Lignes par page',
          }},scopedSlots:_vm._u([{key:`item.prix_fabrication`,fn:function({ item }){return [_vm._v(_vm._s(parseFloat(item.prix_fabrication).toFixed(2))+" €")]}},{key:`item.prix_livraison`,fn:function({ item }){return [_vm._v(_vm._s(parseFloat(item.prix_livraison).toFixed(2))+" €")]}},{key:`item.prix_emballage`,fn:function({ item }){return [_vm._v(_vm._s(parseFloat(item.prix_emballage).toFixed(2))+" €")]}},{key:`item.prix_ttc`,fn:function({ item }){return [_vm._v(_vm._s(parseFloat(item.prix_ttc).toFixed(2))+" €")]}},{key:`item.prix_ht`,fn:function({ item }){return [_vm._v(_vm._s(parseFloat(item.prix_ht).toFixed(2))+" €")]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"py-1 d-flex"},[_c('v-lazy',[_c('OrdersAdminDetail',{attrs:{"id":item.id}})],1),_c('v-btn',{staticClass:"border-shaped ml-1",attrs:{"color":"red","depressed":""},on:{"click":function($event){return _vm.deleteOrder(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.created_at).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.updated_at).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.enabled`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center fill-height",style:(item.enabled
                  ? 'background-color: #4caf50'
                  : 'background-color: #f44336')},[_c('v-switch',{staticClass:"mb-n4 mt-1",attrs:{"color":"white"},on:{"click":function($event){return _vm.toggleOrder(item.id)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:`item.lignes_commande`,fn:function({ item }){return [_c('div',[_c('span',[_vm._v(_vm._s(item.lignes_commande.length))])])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider',{staticClass:"my-6"}),_c('v-card-title',[_c('div',[_vm._v("Actions rapides")])]),_c('v-btn',{staticClass:"border-shaped yellow--bg",attrs:{"depressed":"","large":""},on:{"click":_vm.refreshTarifsCache}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Rafraichir tarifs ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
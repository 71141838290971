<template>
  <v-dialog v-model="dialog" max-width="1100px">
    <template #activator="{ on, attrs }">
      <v-btn
        class="yellow--bg border-shaped"
        v-bind="attrs"
        depressed
        v-on="on"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>
    <v-sheet v-if="loading" class="text-center" shaped elevation="24">
      <v-progress-circular
        :size="100"
        color="yellow"
        indeterminate
        class="my-10"
      ></v-progress-circular>
    </v-sheet>
    <v-card v-if="order" shaped elevation="24">
      <v-card-title class="text-h5 text-lg-h4 font-weight-bold">
        {{ order.uid }}
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="order.f1.id > 2"
              large
              class="ml-auto border-shaped yellow--bg"
              depressed
              :loading="loading_facture"
              v-bind="attrs"
              @click="getFacture"
              v-on="on"
            >
              {{ $t('invoice') }}
            </v-btn>
          </template>
          <span>{{ $t('will_be_recalculated') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6">
              <div>
                <strong
                  >{{ $t('order.price.production') }} :
                  {{ order.prix_fabrication.toFixed(2) }} €</strong
                >
              </div>
              <div>
                <strong
                  >{{ $t('order.price.packaging') }} :
                  {{ order.prix_emballage.toFixed(2) }} €</strong
                >
              </div>
              <div>
                <strong
                  >{{ $t('order.price.shipping') }} :
                  {{ order.prix_livraison.toFixed(2) }} €</strong
                >
              </div>
              <div>
                <strong
                  >{{ $t('order.price.ht') }} :
                  {{ order.prix_ht.toFixed(2) }} €</strong
                >
              </div>
              <div>
                <strong
                  >{{ $t('order.price.ttc') }} :
                  {{ order.prix_ttc.toFixed(2) }} €</strong
                >
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <strong>{{ $t('order.status') }}</strong> :
                {{ $i18n.locale === 'fr' ? order.f1.name : order.f1.en_name }}
              </div>
              <div>
                <strong>{{ $t('global.created_at') }}</strong> :
                {{
                  new Date(
                    new Date(order.created_at).toLocaleString('en-US') + ' UTC'
                  ).toLocaleString()
                }}
              </div>
              <div>
                <strong>{{ $t('global.updated_at') }}</strong> :
                {{
                  new Date(
                    new Date(order.updated_at).toLocaleString('en-US') + ' UTC'
                  ).toLocaleString()
                }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="lignes"
          :items-per-page="5"
          :loading="loading"
          :footer-props="footerProps"
        >
          <template #[`item.id`]="{ item }">
            {{ lignes.indexOf(item) + 1 }}
          </template>
          <template #[`item.product`]="{ item }">
            <v-list style="background: transparent">
              <v-list-item v-if="item.enabled">
                <v-list-item-avatar
                  v-if="$vuetify.breakpoint.smAndUp"
                  rounded
                  size="100"
                >
                  <VignetteFetcher :id="item.product.id" />
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title>{{
                    item.product.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.product.page_count }} pages</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>
                    {{ item.address.address_full }}
                    {{
                      $i18n.locale === 'fr'
                        ? item.address.country.name
                        : item.address.country.en_name
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <strong>{{ $t('address.contact') }}</strong> :
                    {{ item.address.contact }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.address.raison_sociale">
                    <strong>{{ $t('address.raison_sociale') }}</strong> :
                    {{ item.address.raison_sociale }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <template #[`item.f1.name`]="{ item }">
            {{ $i18n.locale === 'fr' ? item.f1.name : item.f1.en_name }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              class="yellow--bg border-shaped"
              depressed
              v-if="item.url_suivi"
              :href="item.url_suivi"
              target="_blank"
            >
              <v-icon>mdi-truck-fast-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="[1, 2].includes(order.f1_id)"
          color="red"
          class="border-shaped ml-auto"
          depressed
          @click="deleteOrder(order.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">

import axios from 'axios'
import { EventBus } from '@/utils'
import VignetteFetcher from '@/components/order/VignetteFetcher'

export default {
  name: 'OrderDetail',
  props: ['id'],
  components: {
    VignetteFetcher
  },
  data() {
    return {
      order: null,
      dialog: null,
      loading: true,
      loading_facture: false,
      apiUrl: process.env.VUE_APP_API_URL
    }
  },
  computed: {
    headers: function () {
      return [
        { text: this.$t('order.id'), align: 'start', value: 'id' },
        { text: this.$t('order.line.product'), value: 'product' },
        { text: this.$t('order.line.quantity'), value: 'quantity' },
        { text: this.$t('order.status'), value: 'f1.name' },
        { text: this.$t('order.comment'), value: 'comment' },
        { text: 'Action', value: 'action' }
      ]
    },
    footerProps: function () {
      return {
        'items-per-page-options': [5, 10, 15, 30, 50, 100, -1],
        itemsPerPageText: this.$t('global.items_per_page')
      }
    },
    lignes: function () {
      try {
        return this.order.lignes_commande.filter(l =>
          l.enabled === true)
      } catch (e) { return [] }
    }
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.getOrder()
      }
    }
  },
  mounted() {
  },
  methods: {
    getOrder() {
      axios
        .get(process.env.VUE_APP_API_URL + '/order', {
          params: {
            order_id: this.id
          },
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          }
        })
        .then(response => {
          this.order = response.data.commande
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getFacture() {
      this.loading_facture = true
      axios
        .get(process.env.VUE_APP_API_URL + '/order/' + this.order.id + '/facture', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`
          },
          responseType: 'blob'
        })
        .then((response) => {
          this.loading_facture = false
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('target', '_blank') // or any other extension
          link.setAttribute('download', this.order.uid + '.pdf') // or any other extension
          link.setAttribute('href', url) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.loading_facture = false
          EventBus.$emit('notif', 'Impossible de générer la facture', 'error')
        })
    },
    deleteOrder(id) {
      if (confirm('Êtes-vous sûr de vouloir supprimer cette commande ?')) {
        axios
          .delete(process.env.VUE_APP_API_URL + '/order/' + id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`
            }
          })
          .then(() => {
            EventBus.$emit('refreshPanier')
            EventBus.$emit('refreshOrders')
          })
          .catch(() => {
            EventBus.$emit('refreshPanier')
            EventBus.$emit('refreshOrders')
          })
      }
    }
  }
}
</script>

<i18n lang="json">
{
  "fr": {
    "will_be_recalculated": "S'il s'agit d'une ancienne commande les prix seront recalculés",
    "invoice": "Facture Proforma"
  },
  "en": {
    "will_be_recalculated": "If it is an old order the prices will be recalculated",
    "invoice": "Proforma Invoice"
  }
}
</i18n>

<template>
  <div>
    <v-btn
      class="border-shaped ml-2 yellow--bg"
      depressed
      @click="toggleDialog"
    >
      <v-icon v-if="item">mdi-pencil</v-icon>
      <v-icon v-else>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px">
      <v-form
        ref="formEditImpressions"
        v-model="valid"
        @submit.prevent="submitImpression"
      >
        <v-card shaped elevation="24">
          <v-card-title class="text-h4 text-lg-h3 font-weight-bold">
            {{ item ? 'Éditer Impression' : 'Ajouter Impression' }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="name"
              label="Libellé"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="en_name"
              label="Libellé Anglais"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="min_page_count"
              label="Nombre de pages minimum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-text-field
              v-model="max_page_count"
              label="Nombre de pages maximum"
              outlined
              clearable
              class="border-shaped"
            ></v-text-field>
            <v-switch v-model="enabled" label="Activé/Désactivé"></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="border-shaped yellow--bg ml-auto"
              type="submit"
              :loading="loading"
              depressed
              large
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/utils'
export default {
  props: ['item'],
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      name: this.item ? this.item.name : '',
      en_name: this.item ? this.item.en_name : '',
      min_page_count: this.item ? this.item.min_page_count : 1,
      max_page_count: this.item ? this.item.max_page_count : 1,
      enabled: this.item ? this.item.enabled : true,
    }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
    },
    submitImpression() {
      this.$refs.formEditImpressions.validate()
      if (this.valid) {
        this.loading = true
        const data = {
          name: this.name,
          en_name: this.en_name,
          enabled: this.enabled,
          min_page_count: this.min_page_count,
          max_page_count: this.max_page_count,
        }
        if (this.item) {
          axios
            .put(
              process.env.VUE_APP_API_URL +
                '/admin/impressions/' +
                this.item.id,
              data,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
                },
              }
            )
            .then(() => {
              EventBus.$emit('refreshimpressions')
              this.dialog = false
              this.loading = false
              EventBus.$emit('notif', 'Impression modifiée', 'success')
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          axios
            .post(process.env.VUE_APP_API_URL + '/admin/impressions', data, {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              },
            })
            .then(() => {
              EventBus.$emit('refreshimpressions')
              this.dialog = false
              this.loading = false
              EventBus.$emit('notif', 'Impression créée', 'success')
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

<template>
  <section class="view-admin">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card-title>
            <v-btn
              v-if="itemType === 'users'"
              :to="{ name: 'Register' }"
              depressed
              class="border-shaped yellow--bg"
            >
              Ajouter un utilisateur
            </v-btn>
            <div v-if="itemType === 'formats'">Formats</div>
            <FinitionsAdminForm v-if="itemType === 'finitions'" />
            <ImpressionsAdminForm v-if="itemType === 'impressions'" />
            <PelliculagesAdminForm v-if="itemType === 'pelliculages'" />
            <PapiersAdminForm
              v-if="
                ['papiersinterieur', 'papierscouverture'].includes(itemType)
              "
              :type-papier="itemType"
            />
            <div v-if="itemType === 'etats'">Etats Produit</div>
            <div v-if="itemType === 'etatscommande'">Etats Commande</div>
            <div v-if="itemType === 'etatslignecommande'">
              Etats Ligne Commande
            </div>
            <AchevesAdminForm v-if="itemType === 'acheves'" />
            <div v-if="itemType === 'tarifslivraison'">Tarifs Livraison</div>
            <CategoryAdminForm v-if="itemType === 'categories'" />
            <CountriesAdminForm v-if="itemType === 'countries'" />
            <div v-if="itemType === 'pending_cart'">Paniers non validés</div>
            <div v-if="itemType === 'simulation_logs'">Simulations passées</div>
            <div v-if="itemType === 'logs'">Logs</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="border-shaped"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
            ></v-text-field>
            <v-btn
              depressed
              @click="getItems"
              class="border-shaped yellow--bg ml-2"
              x-large
              :loading="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="items"
            :items-per-page="itemsPerPage"
            :footer-props="footerProps"
            :options="options"
            class="elevation-1 border-shaped"
            :loading="loading"
            dense
            style="white-space: nowrap"
            :height="screenHeight"
            fixed-header
          >
            <template #[`item.enabled`]="{ item }">
              <div
                class="d-flex align-center justify-center fill-height"
                :style="
                  item.enabled
                    ? 'background-color: #4caf50'
                    : 'background-color: #f44336'
                "
              >
                <v-switch
                  v-model="item.enabled"
                  class="mb-n4 mt-1"
                  color="white"
                  @click="toggleItem(item.id)"
                  flat
                ></v-switch>
              </div>
            </template>
            <template #[`item.is_export`]="{ item }">
              <v-switch
                v-model="item.is_export"
                class="mb-n3 mt-1"
                @click="toggleItem(item.id, 'export')"
              ></v-switch>
            </template>
            <template #[`item.lignes_commande`]="{ item }">
              <div v-if="itemType === 'pending_cart'">
                <span>{{ item.lignes_commande.length }}</span>
              </div>
            </template>
            <template #[`item.roles`]="{ item }">
              <div v-for="role in item.roles" :key="role.id">
                {{ role.name }}
              </div>
            </template>
            <template #[`item.action`]="{ item }">
              <div class="py-1 d-flex">
                <v-btn
                  v-if="itemType === 'users'"
                  class="border-shaped yellow--bg"
                  depressed
                  @click="spoofUser(item.id)"
                >
                  <v-icon>mdi-account-convert-outline</v-icon>
                </v-btn>
                <UsersAdminEdit v-if="itemType === 'users'" :item="item" />
                <FormatsAdminEdit v-if="itemType === 'formats'" :item="item" />
                <FinitionsAdminForm
                  v-if="itemType === 'finitions'"
                  :item="item"
                />
                <ImpressionsAdminForm
                  v-if="itemType === 'impressions'"
                  :item="item"
                />
                <PelliculagesAdminForm
                  v-if="itemType === 'pelliculages'"
                  :item="item"
                />
                <PapiersAdminForm
                  v-if="
                    ['papiersinterieur', 'papierscouverture'].includes(itemType)
                  "
                  :item="item"
                  :type-papier="itemType"
                />
                <EtatsAdminEdit
                  v-if="
                    ['etats', 'etatscommande', 'etatslignecommande'].includes(
                      itemType
                    )
                  "
                  :item="item"
                  :type-etat="itemType"
                />
                <AchevesAdminForm v-if="itemType === 'acheves'" :item="item" />
                <TarifsLivraisonAdminEdit
                  v-if="itemType === 'tarifslivraison'"
                  :item="item"
                />
                <CategoryAdminForm
                  v-if="itemType === 'categories'"
                  :item="item"
                />
                <CountriesAdminForm
                  v-if="itemType === 'countries'"
                  :item="item"
                />
                <div v-if="itemType === 'pending_cart'" class="py-1 d-flex">
                  <v-lazy>
                    <OrdersAdminDetail :id="item.id"></OrdersAdminDetail>
                  </v-lazy>
                  <v-btn
                    color="red"
                    class="border-shaped ml-1"
                    depressed
                    @click="deleteOrder(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  v-if="
                    [
                      'finitions',
                      'impressions',
                      'pelliculages',
                      'papiersinterieur',
                      'papierscouverture',
                      'acheves',
                      'categories',
                      'countries',
                    ].includes(itemType)
                  "
                  depressed
                  color="red"
                  class="border-shaped ml-1"
                  @click="deleteItem(item.id)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.created_at`]="{ item }">
              {{
                new Date(
                  new Date(item.created_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.updated_at`]="{ item }">
              {{
                new Date(
                  new Date(item.updated_at).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.last_login`]="{ item }">
              {{
                new Date(
                  new Date(item.last_login).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.last_activity`]="{ item }">
              {{
                new Date(
                  new Date(item.last_activity).toLocaleString('en-US') + ' UTC'
                ).toLocaleString()
              }}
            </template>
            <template #[`item.user`]="{ item }">
              <span v-if="item.user">{{ item.user.username }}</span>
              <span v-else class="font-italic grey--text">Aucun</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/utils'

import FormatsAdminEdit from '@/components/admin/formats/FormatsAdminEdit'
import FinitionsAdminForm from '@/components/admin/finitions/FinitionsAdminForm'
import ImpressionsAdminForm from '@/components/admin/impressions/ImpressionsAdminForm'
import PelliculagesAdminForm from '@/components/admin/pelliculages/PelliculagesAdminForm'
import PapiersAdminForm from '@/components/admin/papiers/PapiersAdminForm'
import EtatsAdminEdit from '@/components/admin/etats/EtatsAdminEdit'
import AchevesAdminForm from '@/components/admin/acheves/AchevesAdminForm'
import TarifsLivraisonAdminEdit from '@/components/admin/tarifs/TarifsLivraisonAdminEdit'
import UsersAdminEdit from '@/components/admin/users/UsersAdminEdit'
import CountriesAdminForm from '@/components/admin/countries/CountriesAdminForm'
import CategoryAdminForm from '@/components/admin/categories/CategoryAdminForm'
import OrdersAdminDetail from '@/components/admin/orders/OrdersAdminDetail'

export default {
  components: {
    CategoryAdminForm,
    CountriesAdminForm,
    FormatsAdminEdit,
    FinitionsAdminForm,
    ImpressionsAdminForm,
    PelliculagesAdminForm,
    PapiersAdminForm,
    EtatsAdminEdit,
    AchevesAdminForm,
    TarifsLivraisonAdminEdit,
    UsersAdminEdit,
    OrdersAdminDetail,
  },
  props: ['itemType', 'perPage', 'sortBy', 'headers'],
  data() {
    return {
      screenHeight: window.innerHeight - 250,
      search: null,
      loading: true,
      items: [],
      itemsPerPage: this.perPage || 30,
      footerProps: {
        'items-per-page-options': [
          5, 10, 15, 30, 50, 100, 200, 300, 500, 1000, 5000, -1,
        ],
        itemsPerPageText: 'Lignes par page',
      },
      options: {
        sortBy: [this.sortBy || 'updated_at'],
        sortDesc: [true],
      },
    }
  },
  mounted() {
    EventBus.$on(`refresh${this.itemType}`, () => {
      this.getItems()
    })

    EventBus.$on('successAuthentication', () => {
      this.$router.push({ name: 'Home' }).catch(() => {})
    })
    this.getItems()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.screenHeight =
        window.innerHeight - (this.$vuetify.breakpoint.lgAndUp ? 250 : 300)
    },
    getItems() {
      this.loading = true
      axios
        .get(process.env.VUE_APP_API_URL + `/admin/${this.itemType}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    toggleItem(id, type = null) {
      axios
        .patch(
          process.env.VUE_APP_API_URL + `/admin/${this.itemType}/` + id,
          type ? { type: type } : null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
            },
          }
        )
        .then(() => {
          switch (this.itemType) {
            case 'users':
              EventBus.$emit('notif', 'Utilisateur modifié', 'success')
              break
            case 'commandes':
              EventBus.$emit('notif', 'Commande modifiée', 'success')
              break
            case 'formats':
              EventBus.$emit('notif', 'Format modifié', 'success')
              break
            case 'finitions':
              EventBus.$emit('notif', 'Finition modifiée', 'success')
              break
            case 'impressions':
              EventBus.$emit('notif', 'Impression modifiée', 'success')
              break
            case 'pelliculages':
              EventBus.$emit('notif', 'Pelliculage modifié', 'success')
              break
            case 'papiersinterieur':
              EventBus.$emit('notif', 'Papier Intérieur modifié', 'success')
              break
            case 'papierscouverture':
              EventBus.$emit('notif', 'Papier Couverture modifié', 'success')
              break
            case 'etats':
              EventBus.$emit('notif', 'Etat modifié', 'success')
              break
            case 'etatscommande':
              EventBus.$emit('notif', 'Etat Commande modifié', 'success')
              break
            case 'etatslignecommande':
              EventBus.$emit('notif', 'Etat Ligne Commande modifié', 'success')
              break
            case 'acheves':
              EventBus.$emit('notif', 'Achève modifié', 'success')
              break
            case 'tarifslivraison':
              EventBus.$emit('notif', 'Tarif Livraison modifié', 'success')
              break
            case 'categories':
              EventBus.$emit('notif', 'Catégorie modifiée', 'success')
              break
            case 'countries':
              EventBus.$emit('notif', 'Pays modifié', 'success')
              break
            default:
              break
          }
        })
        .catch(() => {
          EventBus.$emit('notif', "Une erreur s'est produite", 'error')
          this.getItems()
        })
    },
    deleteItem(id) {
      if (
        confirm(
          "Êtes-vous sûr de vouloir continuer ? l'objet sera définitivement supprimé"
        )
      ) {
        axios
          .delete(
            process.env.VUE_APP_API_URL + `/admin/${this.itemType}/` + id,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
              },
            }
          )
          .then(() => {
            switch (this.itemType) {
              case 'users':
                EventBus.$emit('notif', 'Utilisateur supprimé', 'success')
                break
              case 'commandes':
                EventBus.$emit('notif', 'Commande supprimée', 'success')
                break
              case 'formats':
                EventBus.$emit('notif', 'Format supprimé', 'success')
                break
              case 'finitions':
                EventBus.$emit('notif', 'Finition supprimée', 'success')
                break
              case 'impressions':
                EventBus.$emit('notif', 'Impression supprimée', 'success')
                break
              case 'pelliculages':
                EventBus.$emit('notif', 'Pelliculage supprimé', 'success')
                break
              case 'papiersinterieur':
                EventBus.$emit('notif', 'Papier Intérieur supprimé', 'success')
                break
              case 'papierscouverture':
                EventBus.$emit('notif', 'Papier Couverture supprimé', 'success')
                break
              case 'etats':
                EventBus.$emit('notif', 'Etat supprimé', 'success')
                break
              case 'etatscommande':
                EventBus.$emit('notif', 'Etat Commande supprimé', 'success')
                break
              case 'etatslignecommande':
                EventBus.$emit(
                  'notif',
                  'Etat Ligne Commande supprimé',
                  'success'
                )
                break
              case 'acheves':
                EventBus.$emit('notif', 'Achève supprimé', 'success')
                break
              case 'tarifslivraison':
                EventBus.$emit('notif', 'Tarif Livraison supprimé', 'success')
                break
              case 'categories':
                EventBus.$emit('notif', 'Catégorie supprimée', 'success')
                break
              case 'countries':
                EventBus.$emit('notif', 'Pays supprimé', 'success')
                break
              default:
                break
            }
            this.getItems()
          })
          .catch((e) => {
            EventBus.$emit(
              'notif',
              "Une erreur s'est produite : " + e.response.data.message,
              'error'
            )
            this.getItems()
          })
      }
    },
    deleteOrder(id) {
      if (confirm('Êtes-vous sûr de vouloir supprimer cette commande ?')) {
        axios
          .delete(process.env.VUE_APP_API_URL + '/admin/orders/' + id, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
            },
          })
          .then(() => {
            EventBus.$emit('notif', 'Etat Commande supprimé', 'success')
            this.getItems()
          })
          .catch(() => {
            EventBus.$emit(
              'notif',
              "Une erreur s'est produite : " + e.response.data.message,
              'error'
            )
            this.getItems()
          })
      }
    },
    spoofUser(id) {
      this.$store.dispatch('spoof', id)
    },
  },
}
</script>

<style lang="scss" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"view-admin"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card-title',[(_vm.itemType === 'users')?_c('v-btn',{staticClass:"border-shaped yellow--bg",attrs:{"to":{ name: 'Register' },"depressed":""}},[_vm._v(" Ajouter un utilisateur ")]):_vm._e(),(_vm.itemType === 'formats')?_c('div',[_vm._v("Formats")]):_vm._e(),(_vm.itemType === 'finitions')?_c('FinitionsAdminForm'):_vm._e(),(_vm.itemType === 'impressions')?_c('ImpressionsAdminForm'):_vm._e(),(_vm.itemType === 'pelliculages')?_c('PelliculagesAdminForm'):_vm._e(),(
              ['papiersinterieur', 'papierscouverture'].includes(_vm.itemType)
            )?_c('PapiersAdminForm',{attrs:{"type-papier":_vm.itemType}}):_vm._e(),(_vm.itemType === 'etats')?_c('div',[_vm._v("Etats Produit")]):_vm._e(),(_vm.itemType === 'etatscommande')?_c('div',[_vm._v("Etats Commande")]):_vm._e(),(_vm.itemType === 'etatslignecommande')?_c('div',[_vm._v(" Etats Ligne Commande ")]):_vm._e(),(_vm.itemType === 'acheves')?_c('AchevesAdminForm'):_vm._e(),(_vm.itemType === 'tarifslivraison')?_c('div',[_vm._v("Tarifs Livraison")]):_vm._e(),(_vm.itemType === 'categories')?_c('CategoryAdminForm'):_vm._e(),(_vm.itemType === 'countries')?_c('CountriesAdminForm'):_vm._e(),(_vm.itemType === 'pending_cart')?_c('div',[_vm._v("Paniers non validés")]):_vm._e(),(_vm.itemType === 'simulation_logs')?_c('div',[_vm._v("Simulations passées")]):_vm._e(),(_vm.itemType === 'logs')?_c('div',[_vm._v("Logs")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"border-shaped",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"border-shaped yellow--bg ml-2",attrs:{"depressed":"","x-large":"","loading":_vm.loading},on:{"click":_vm.getItems}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"elevation-1 border-shaped",staticStyle:{"white-space":"nowrap"},attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"options":_vm.options,"loading":_vm.loading,"dense":"","height":_vm.screenHeight,"fixed-header":""},scopedSlots:_vm._u([{key:`item.enabled`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-center fill-height",style:(item.enabled
                  ? 'background-color: #4caf50'
                  : 'background-color: #f44336')},[_c('v-switch',{staticClass:"mb-n4 mt-1",attrs:{"color":"white","flat":""},on:{"click":function($event){return _vm.toggleItem(item.id)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:`item.is_export`,fn:function({ item }){return [_c('v-switch',{staticClass:"mb-n3 mt-1",on:{"click":function($event){return _vm.toggleItem(item.id, 'export')}},model:{value:(item.is_export),callback:function ($$v) {_vm.$set(item, "is_export", $$v)},expression:"item.is_export"}})]}},{key:`item.lignes_commande`,fn:function({ item }){return [(_vm.itemType === 'pending_cart')?_c('div',[_c('span',[_vm._v(_vm._s(item.lignes_commande.length))])]):_vm._e()]}},{key:`item.roles`,fn:function({ item }){return _vm._l((item.roles),function(role){return _c('div',{key:role.id},[_vm._v(" "+_vm._s(role.name)+" ")])})}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"py-1 d-flex"},[(_vm.itemType === 'users')?_c('v-btn',{staticClass:"border-shaped yellow--bg",attrs:{"depressed":""},on:{"click":function($event){return _vm.spoofUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-account-convert-outline")])],1):_vm._e(),(_vm.itemType === 'users')?_c('UsersAdminEdit',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'formats')?_c('FormatsAdminEdit',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'finitions')?_c('FinitionsAdminForm',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'impressions')?_c('ImpressionsAdminForm',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'pelliculages')?_c('PelliculagesAdminForm',{attrs:{"item":item}}):_vm._e(),(
                  ['papiersinterieur', 'papierscouverture'].includes(_vm.itemType)
                )?_c('PapiersAdminForm',{attrs:{"item":item,"type-papier":_vm.itemType}}):_vm._e(),(
                  ['etats', 'etatscommande', 'etatslignecommande'].includes(
                    _vm.itemType
                  )
                )?_c('EtatsAdminEdit',{attrs:{"item":item,"type-etat":_vm.itemType}}):_vm._e(),(_vm.itemType === 'acheves')?_c('AchevesAdminForm',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'tarifslivraison')?_c('TarifsLivraisonAdminEdit',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'categories')?_c('CategoryAdminForm',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'countries')?_c('CountriesAdminForm',{attrs:{"item":item}}):_vm._e(),(_vm.itemType === 'pending_cart')?_c('div',{staticClass:"py-1 d-flex"},[_c('v-lazy',[_c('OrdersAdminDetail',{attrs:{"id":item.id}})],1),_c('v-btn',{staticClass:"border-shaped ml-1",attrs:{"color":"red","depressed":""},on:{"click":function($event){return _vm.deleteOrder(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),(
                  [
                    'finitions',
                    'impressions',
                    'pelliculages',
                    'papiersinterieur',
                    'papierscouverture',
                    'acheves',
                    'categories',
                    'countries',
                  ].includes(_vm.itemType)
                )?_c('v-btn',{staticClass:"border-shaped ml-1",attrs:{"depressed":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.created_at).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.updated_at).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.last_login`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.last_login).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.last_activity`,fn:function({ item }){return [_vm._v(" "+_vm._s(new Date( new Date(item.last_activity).toLocaleString('en-US') + ' UTC' ).toLocaleString())+" ")]}},{key:`item.user`,fn:function({ item }){return [(item.user)?_c('span',[_vm._v(_vm._s(item.user.username))]):_c('span',{staticClass:"font-italic grey--text"},[_vm._v("Aucun")])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }